@import url("https://use.typekit.net/wbj0pzi.css");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "canada-type-gibson", sans-serif;
  color: #333;
}

.buy__section {
  background-color: #e6e7e8;
}
@media (max-width: 750px) {
  .buy__section {
    min-height: 60rem;
  }
}
@media (max-width: 600px) {
  .buy__section {
    min-height: 70rem;
  }
}
@media (max-width: 450px) {
  .buy__section {
    min-height: 90rem;
  }
}
@media (max-width: 400px) {
  .buy__section {
    min-height: 100rem;
  }
}
.buy__container {
  display: grid;
  grid-template-columns: 60% 40%;
  position: relative;
}
@media (max-width: 450px) {
  .buy__container {
    display: flex;
    flex-direction: column;
  }
}
.buy__content {
  padding-top: 4rem;
  padding-right: 0;
  width: 80%;
  margin: 0 auto;
  z-index: 1000;
}
@media (max-width: 850px) {
  .buy__content {
    padding-top: 3rem;
  }
}
.buy__heading {
  color: black;
  font-size: 5rem;
  font-weight: 500;
}
@media (max-width: 850px) {
  .buy__heading {
    font-size: 4rem;
  }
}
.buy__heading--sub {
  font-size: 2rem;
  font-weight: 400;
  padding: 1rem 0;
  line-height: 2.8rem;
  color: black;
}
@media (max-width: 850px) {
  .buy__heading--sub {
    line-height: 2.4rem;
    font-size: 1.6rem;
  }
}
.buy__banner {
  max-width: 100%;
  margin-top: 2rem;
}
@media (max-width: 750px) {
  .buy__banner {
    display: none;
  }
}
.buy__locations {
  display: flex;
  margin: 0 auto;
}
.buy__store {
  margin-top: 4rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 0.5rem;
}
.buy__stores {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8rem;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #ffffff;
  border: 2px solid black;
}
@media (max-width: 1250px) {
  .buy__stores {
    font-size: 1rem;
  }
}
@media (max-width: 1000px) {
  .buy__stores {
    margin-top: 2rem;
  }
}
@media (max-width: 750px) {
  .buy__stores {
    grid-template-columns: repeat(4, 1fr);
  }
}
.buy__stores--title-domestic {
  grid-column: 1/-1;
  padding: 2rem;
  margin-bottom: 0;
  border: 1px solid black;
}
@media (max-width: 1250px) {
  .buy__stores--title-domestic {
    font-size: 1.6rem;
    padding: 1rem;
  }
}
@media (max-width: 1100px) {
  .buy__stores--title-domestic {
    padding: 0.5rem;
  }
}
.buy__stores--links {
  text-decoration: none;
  color: #333;
  cursor: pointer;
}
@media (max-width: 1250px) {
  .buy__stores--links {
    font-size: 1.2rem;
  }
}
.buy__stores--location {
  border: 1px solid black;
  padding-top: 2rem;
  padding-bottom: 1rem;
  height: 100%;
  transition: all 0.2s ease-in-out;
}
.buy__stores--location:hover {
  color: white;
  background-color: black;
}
@media (max-width: 1100px) {
  .buy__stores--location {
    padding: 0.5rem;
  }
}
.buy__img {
  height: 12rem;
}
.buy__text {
  position: absolute;
  width: 50%;
  top: 15rem;
  right: 10rem;
}
.buy__text--heading {
  font-size: 5.5rem;
  font-weight: 500;
  font-style: italic;
  color: black;
}
.buy__text--sub-heading {
  font-size: 3rem;
  font-weight: 500;
  color: black;
}
.buy__text--sub-text {
  font-size: 2rem;
  font-weight: 400;
  padding: 1rem 0;
  line-height: 2.8rem;
}
.buy__text--job-title {
  font-size: 2rem;
  font-weight: 200;
  font-style: italic;
  color: black;
}
.buy__button {
  padding: 1rem 3rem;
  font-family: "canada-type-gibson", sans-serif;
  font-size: 2em;
  color: black;
  background-color: white;
  border: 2px solid black;
  z-index: 100000;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  text-align: center;
}
@media (max-width: 400px) {
  .buy__button {
    width: 80%;
  }
}
.buy__button:hover {
  cursor: pointer;
  transform: scale(1.05);
}

#buy {
  margin-top: 1rem;
}

#summit__image {
  margin-top: 5px;
  height: 11rem;
}

.fitment__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: #baeaf7;
  position: relative;
}
@media (max-width: 900px) {
  .fitment__container {
    display: flex;
    flex-direction: column;
  }
}
.fitment__content {
  padding: 8rem;
  padding-bottom: 0rem;
  padding-left: 10rem;
  width: 55vw;
  margin: 0 auto;
}
@media (max-width: 900px) {
  .fitment__content {
    padding: 2rem;
    width: 90%;
  }
}
@media (max-width: 650px) {
  .fitment__content {
    padding-top: 2rem;
  }
}
.fitment__heading {
  color: black;
  font-size: 5rem;
  line-height: 5.5rem;
  font-weight: 500;
}
@media (max-width: 850px) {
  .fitment__heading {
    font-size: 4rem;
    line-height: 4rem;
  }
}
.fitment__heading--secondary {
  font-size: 3rem;
  font-weight: 500;
  color: black;
  margin: 20rem 5rem 3rem 0rem;
}
@media (max-width: 900px) {
  .fitment__heading--secondary {
    margin: 2rem;
    text-align: center;
  }
}
@media (max-width: 650px) {
  .fitment__heading--secondary {
    font-size: 2rem;
  }
}
@media (max-width: 900px) {
  .fitment__heading--secondary-boxes {
    text-align: center;
    padding-right: 0rem;
  }
}
.fitment__heading--secondary-boxes > p {
  font-size: 1.2rem;
  margin-top: 0.5rem;
  width: 90%;
}
@media (max-width: 900px) {
  .fitment__heading--secondary-boxes > p {
    padding-left: 3rem;
    font-size: 1rem;
  }
}
.fitment__heading--sub {
  font-size: 2rem;
  font-weight: 400;
  padding: 2rem 1rem;
  line-height: 2.8rem;
  padding-left: 0rem;
}
@media (max-width: 1200px) {
  .fitment__heading--sub {
    font-size: 1.6rem;
  }
}
@media (max-width: 850px) {
  .fitment__heading--sub {
    line-height: 2.4rem;
  }
}
@media (max-width: 650px) {
  .fitment__heading--sub {
    font-size: 1.6rem;
  }
}
.fitment__heading--sub-cols {
  display: flex;
}
@media (max-width: 900px) {
  .fitment__heading--sub-cols {
    display: block;
  }
}
.fitment__dropdowns > select {
  height: 4rem;
  width: 30%;
  margin-right: 1rem;
  font-family: "canada-type-gibson", sans-serif;
  font-size: 2rem;
}
@media (max-width: 1200px) {
  .fitment__dropdowns > select {
    height: 3rem;
    font-size: 1.6rem;
  }
}
.fitment__dropdowns > select:hover {
  cursor: pointer;
}
.fitment__img {
  max-width: 100%;
  grid-column: 1/-1;
  margin: 0 auto;
}
.fitment__chart {
  position: absolute;
  top: 55rem;
  z-index: 1000;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 950px) {
  .fitment__chart {
    top: 60rem;
  }
}
@media (max-width: 650px) {
  .fitment__chart {
    top: 50rem;
  }
}
@media (max-width: 650px) {
  .fitment__chart > div > table {
    width: 100%;
  }
}
.fitment__chart--title {
  color: white;
  background-color: black;
  font-weight: 400;
  font-size: 3rem;
  width: 30rem;
  padding: 1rem;
  border: 1px solid #333;
  text-align: center;
}
@media (max-width: 1200px) {
  .fitment__chart--title {
    font-size: 2rem;
  }
}
@media (max-width: 650px) {
  .fitment__chart--title {
    font-size: 1.4rem;
  }
}
.fitment__chart--data {
  background-color: #e8f3f7;
  font-size: 2.5rem;
  padding: 1rem;
  border: 1px solid #333;
  text-align: center;
}
@media (max-width: 1200px) {
  .fitment__chart--data {
    font-size: 2rem;
  }
}
@media (max-width: 650px) {
  .fitment__chart--data {
    font-size: 1.2rem;
  }
}
.fitment__button {
  margin-top: 3rem;
  padding: 1rem 3rem;
  font-family: "canada-type-gibson", sans-serif;
  font-size: 2em;
  color: #4fc7e7;
  background-color: white;
  border: 2px solid #4fc7e7;
}
.fitment__button:hover {
  cursor: pointer;
}

.landing {
  max-width: 100vw;
  overflow: hidden;
  display: flex;
}
.landing__image {
  margin: 0 auto;
  width: 100%;
}
.landing__carousel {
  max-width: 100vw;
}
.landing__carousel--item {
  width: 100vw;
}
.landing__carousel--img {
  margin: 0 auto;
  width: 100vw;
}

.promise__container {
  display: grid;
  grid-template-columns: 30% 70%;
  overflow: hidden;
  margin-top: 2rem;
}
@media (max-width: 1150px) {
  .promise__container {
    display: flex;
    flex-direction: column;
  }
}
.promise__content {
  padding: 8rem;
  padding-right: 0;
  padding-left: 10rem;
  width: 120%;
  margin: 0 auto;
  z-index: 1000;
  margin-top: 8rem;
}
@media (max-width: 1450px) {
  .promise__content {
    width: 100%;
    font-size: 1rem;
  }
}
@media (max-width: 1150px) {
  .promise__content {
    padding-bottom: 3rem;
    padding-top: 5rem;
    padding-right: 4rem;
    margin-top: 8rem;
  }
}
@media (max-width: 850px) {
  .promise__content {
    padding: 4rem;
    padding-top: 6rem;
    margin-bottom: 2rem;
  }
}
@media (max-width: 600px) {
  .promise__content {
    margin-top: 5rem;
    margin-bottom: 8rem;
  }
}
.promise__heading {
  color: black;
  font-size: 5rem;
  font-weight: 500;
}
@media (max-width: 850px) {
  .promise__heading {
    font-size: 4rem;
  }
}
.promise__heading--sub {
  font-size: 2rem;
  font-weight: 400;
  padding: 1rem 0;
  line-height: 2.8rem;
  margin-bottom: 5rem;
}
@media (max-width: 1450px) {
  .promise__heading--sub {
    font-size: 1.6rem;
  }
}
@media (max-width: 850px) {
  .promise__heading--sub {
    padding-bottom: 0rem;
    line-height: 2.4rem;
  }
}
.promise__img {
  margin-top: 4rem;
  max-width: 100%;
}
@media (max-width: 600px) {
  .promise__img {
    margin-top: -1rem;
  }
}
@media (max-width: 600px) {
  .promise__img {
    margin-top: 15rem;
  }
}
.promise__phrase {
  position: relative;
  margin-bottom: -0.3rem;
}
@media (max-width: 1150px) {
  .promise__phrase {
    margin-top: -5rem;
  }
}
.promise__text {
  position: absolute;
  width: 50%;
  top: 8rem;
  right: 10rem;
}
@media (max-width: 1650px) {
  .promise__text {
    top: 8rem;
    right: 8rem;
  }
}
@media (max-width: 1300px) {
  .promise__text {
    top: 6rem;
    right: 6rem;
  }
}
@media (max-width: 750px) {
  .promise__text {
    top: 4rem;
    right: 4rem;
  }
}
@media (max-width: 700px) {
  .promise__text {
    font-size: 1.6rem;
  }
}
@media (max-width: 600px) {
  .promise__text {
    margin-top: -5rem;
    width: 80%;
  }
}
.promise__text--box {
  margin-left: 30rem;
  margin-top: 2rem;
}
@media (max-width: 1250px) {
  .promise__text--box {
    margin-left: 10rem;
    margin-top: -2rem;
  }
}
@media (max-width: 750px) {
  .promise__text--box {
    margin-top: 0.5rem;
    margin-left: 5rem;
  }
}
@media (max-width: 700px) {
  .promise__text--box {
    margin-top: 0rem;
    margin-left: 5rem;
  }
}
.promise__text--heading {
  font-size: 5rem;
  font-weight: 500;
  font-style: italic;
  color: black;
}
@media (max-width: 1650px) {
  .promise__text--heading {
    font-size: 4rem;
  }
}
@media (max-width: 1350px) {
  .promise__text--heading {
    font-size: 3rem;
  }
}
@media (max-width: 700px) {
  .promise__text--heading {
    font-size: 2rem;
  }
}
.promise__text--sub-heading {
  font-size: 3rem;
  font-weight: 500;
  color: black;
}
@media (max-width: 1350px) {
  .promise__text--sub-heading {
    font-size: 2rem;
  }
}
@media (max-width: 700px) {
  .promise__text--sub-heading {
    margin-top: -1rem;
    font-size: 1.6rem;
  }
}
.promise__text--sub-text {
  font-size: 2rem;
  font-weight: 400;
  padding: 1rem 0;
  line-height: 2.8rem;
}
@media (max-width: 1350px) {
  .promise__text--sub-text {
    font-size: 1.6rem;
  }
}
@media (max-width: 750px) {
  .promise__text--sub-text {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
@media (max-width: 700px) {
  .promise__text--sub-text {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
}
.promise__text--job-title {
  font-size: 2rem;
  font-weight: 200;
  font-style: italic;
  color: black;
}
@media (max-width: 1350px) {
  .promise__text--job-title {
    font-size: 1.6rem;
  }
}
@media (max-width: 700px) {
  .promise__text--job-title {
    margin-top: -1rem;
    font-size: 1.3rem;
    font-weight: 500;
  }
}
.promise__button {
  padding: 1rem 3rem;
  font-family: "canada-type-gibson", sans-serif;
  font-size: 2em;
  color: black;
  background-color: white;
  border: 2px solid black;
  transition: all 0.2s ease-in-out;
}
.promise__button:hover {
  cursor: pointer;
  font-size: 2.3em;
}
@media (max-width: 1150px) {
  .promise__button {
    margin-left: 60%;
  }
}
@media (max-width: 600px) {
  .promise__button {
    margin: 0 auto;
  }
}

#our-promise {
  margin-top: -10rem;
}

.specs__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow-x: hidden;
}
@media (max-width: 1100px) {
  .specs__container {
    display: flex;
    flex-direction: column;
  }
}
.specs__content {
  padding: 6rem;
  width: 45vw;
  margin: 0 auto;
  padding-bottom: 0rem;
}
@media (max-width: 1100px) {
  .specs__content {
    width: 95%;
    padding: 3rem 1rem;
  }
}
@media (max-width: 650px) {
  .specs__content {
    padding-bottom: 0rem;
  }
}
.specs__heading {
  color: black;
  font-size: 5rem;
  font-weight: 500;
  line-height: 5.5rem;
}
@media (max-width: 850px) {
  .specs__heading {
    font-size: 4rem;
    line-height: 4rem;
  }
}
.specs__heading--box {
  width: 60%;
}
@media (max-width: 850px) {
  .specs__heading--box {
    width: 95%;
  }
}
.specs__heading--sub {
  font-size: 1.8rem;
  font-weight: 400;
  padding: 0.5rem 0;
  line-height: 2.8rem;
}
@media (max-width: 1700px) {
  .specs__heading--sub {
    font-size: 1.6rem;
  }
}
@media (max-width: 1250px) {
  .specs__heading--sub {
    font-size: 1.4rem;
  }
}
@media (max-width: 850px) {
  .specs__heading--sub {
    line-height: 2.4rem;
    padding-left: 1rem;
  }
}
@media (max-width: 400px) {
  .specs__heading--sub {
    margin-bottom: 4rem;
  }
}
.specs__img {
  grid-row: 1/-1;
  grid-column: 1/-1;
  justify-self: center;
  align-self: center;
  height: 80%;
}
@media (max-width: 1700px) {
  .specs__img {
    height: 70%;
  }
}
@media (max-width: 1250px) {
  .specs__img {
    height: 60%;
  }
}
@media (max-width: 1100px) {
  .specs__img {
    height: 100%;
  }
}
@media (max-width: 650px) {
  .specs__img {
    height: 60%;
  }
}
.specs__img--box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 17rem 50rem 17rem;
  width: 90%;
}
@media (max-width: 1700px) {
  .specs__img--box {
    grid-template-rows: 30% 40% 30%;
  }
}
@media (max-width: 1100px) {
  .specs__img--box {
    margin: 0 auto;
    height: 50rem;
    width: 70%;
    margin-top: -2rem;
    margin-bottom: 5rem;
  }
}
@media (max-width: 850px) {
  .specs__img--box {
    width: 90%;
  }
}
@media (max-width: 650px) {
  .specs__img--box {
    grid-template-rows: 40% 20% 40%;
  }
}
@media (max-width: 450px) {
  .specs__img--box {
    grid-template-rows: 30% 40% 30%;
    margin-top: 0.5rem;
  }
}
.specs__img--rule {
  grid-row: 2/3;
  grid-column: 1/-1;
  border-top: 2px solid #4fc7e7;
  border-bottom: 2px solid #4fc7e7;
}
.specs__text--1 {
  grid-row: 1/2;
  grid-column: 1/2;
  align-self: end;
  width: 48%;
  margin-bottom: 5rem;
}
@media (max-width: 1700px) {
  .specs__text--1 {
    font-size: 1.8rem;
    margin-bottom: 3rem;
  }
}
@media (max-width: 1250px) {
  .specs__text--1 {
    font-size: 1.6rem;
    margin-bottom: 2.8rem;
  }
}
@media (max-width: 450px) {
  .specs__text--1 {
    align-self: start;
    min-width: 9rem;
  }
}
.specs__text--2 {
  grid-row: 1/2;
  grid-column: 2/3;
  align-self: end;
  justify-self: end;
  text-align: end;
  width: 48%;
  margin-bottom: 2.5rem;
}
@media (max-width: 1700px) {
  .specs__text--2 {
    font-size: 1.8rem;
    margin-bottom: 3rem;
  }
}
@media (max-width: 1250px) {
  .specs__text--2 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
}
@media (max-width: 450px) {
  .specs__text--2 {
    align-self: start;
    min-width: 11rem;
  }
}
.specs__text--3 {
  grid-row: 3/4;
  grid-column: 1/2;
  align-self: start;
  width: 50%;
  margin-top: 2rem;
}
@media (max-width: 1700px) {
  .specs__text--3 {
    font-size: 1.8rem;
  }
}
@media (max-width: 1250px) {
  .specs__text--3 {
    font-size: 1.6rem;
    margin-top: 1rem;
  }
}
@media (max-width: 450px) {
  .specs__text--3 {
    align-self: end;
    min-width: 11rem;
  }
}
.specs__text--4 {
  grid-row: 3/4;
  grid-column: 2/3;
  align-self: start;
  justify-self: end;
  text-align: end;
  width: 48%;
  margin-top: 2rem;
}
@media (max-width: 1700px) {
  .specs__text--4 {
    font-size: 1.8rem;
  }
}
@media (max-width: 1250px) {
  .specs__text--4 {
    font-size: 1.6rem;
    width: 42%;
    margin-top: 1rem;
    min-width: 11rem;
  }
}
@media (max-width: 450px) {
  .specs__text--4 {
    align-self: end;
  }
}
.specs__chart {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.specs__chart--title {
  color: white;
  background-color: black;
  font-weight: 500;
  padding: 1rem;
  font-size: 1.8rem;
  margin: 0;
}
@media (max-width: 1250px) {
  .specs__chart--title {
    font-size: 1.6rem;
  }
}
@media (max-width: 450px) {
  .specs__chart--title {
    font-size: 1.2rem;
  }
}
@media (max-width: 350px) {
  .specs__chart--title {
    font-size: 1rem;
  }
}
.specs__chart--data {
  display: flex;
  height: 26.8rem;
}
@media (max-width: 1250px) {
  .specs__chart--data {
    height: 25rem;
  }
}
.specs__chart--data-odd {
  background-color: #e8f3f7;
  width: 33.33%;
}
.specs__chart--data-even {
  background-color: #dcf4fa;
  width: 33.33%;
}
.specs__chart--spec {
  font-size: 1.6em;
  font-weight: 500;
  padding: 0.5rem;
  color: black;
}
@media (max-width: 1250px) {
  .specs__chart--spec {
    font-size: 1.4rem;
  }
}
@media (max-width: 450px) {
  .specs__chart--spec {
    font-size: 1rem;
  }
}
@media (max-width: 350px) {
  .specs__chart--spec {
    font-size: 0.8rem;
  }
}
.specs__chart--spec:nth-child(even) {
  background-color: #bfbfbf;
}
.specs__button {
  margin-top: 2rem;
  padding: 1rem 3rem;
  font-family: "canada-type-gibson", sans-serif;
  font-size: 2em;
  color: #4fc7e7;
  background-color: white;
  border: 2px solid #4fc7e7;
}
.specs__button:hover {
  cursor: pointer;
}

#first__box {
  height: 3rem;
}
@media (max-width: 450px) {
  #first__box {
    height: 2.6rem;
  }
}

.footer {
  background-color: black;
  height: 10rem;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}
@media (max-width: 550px) {
  .footer {
    height: 8rem;
  }
}
.footer__logo {
  margin: 0 auto;
  margin-top: 3rem;
}
@media (max-width: 850px) {
  .footer__logo {
    width: 30rem;
  }
}
@media (max-width: 550px) {
  .footer__logo {
    margin-top: 2rem;
    width: 20rem;
  }
}
.footer__social {
  margin: 0 auto;
  width: 20rem;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 550px) {
  .footer__social {
    width: 10rem;
  }
}
.footer__social--icon {
  height: 3rem;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 550px) {
  .footer__social--icon {
    height: 2rem;
  }
}
.footer__social--icon:hover {
  transform: scale(1.1);
}
.footer__text {
  font-size: 1.1rem;
  margin: 0 auto;
  padding-top: 1rem;
  color: #e0e0e0;
}
@media (max-width: 550px) {
  .footer__text {
    font-size: 1rem;
  }
}

.header {
  background-color: #000;
  height: 10rem;
  display: flex;
  justify-content: space-between;
  z-index: 1000000 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
}
@media (max-width: 1500px) {
  .header {
    height: 8rem;
  }
}
@media (max-width: 1100px) {
  .header {
    height: 6rem;
  }
}
.header__logo {
  height: 7rem;
  margin-left: 4rem;
  margin-top: 2rem;
}
@media (max-width: 1500px) {
  .header__logo {
    height: 5rem;
    margin-left: 3rem;
    margin-top: 1.5rem;
  }
}
@media (max-width: 1100px) {
  .header__logo {
    height: 4rem;
    margin-left: 2rem;
    margin-top: 1rem;
  }
}
@media (max-width: 650px) {
  .header__logo {
    height: 3.5rem;
  }
}
@media (max-width: 850px) {
  .header__nav {
    display: none;
  }
}
.header__list {
  display: flex;
  justify-content: end;
  list-style-type: none;
  margin: 0 5rem;
}
@media (max-width: 1500px) {
  .header__list {
    margin: 0 3rem;
  }
}
@media (max-width: 1100px) {
  .header__list {
    margin: 0 2rem;
  }
}
.header__list-link {
  text-decoration: none;
  color: #aaa;
  transition: all 0.2s ease-in-out;
}
.header__list-link:hover, .header__list-link:active, .header__list-link:focus {
  color: white;
}
.header__list-items {
  font-size: 1.8em;
  font-weight: 400;
  padding: 0.8rem 1.8rem;
  margin-top: 3.5rem;
  margin-bottom: 3rem;
}
@media (max-width: 1500px) {
  .header__list-items {
    font-size: 1.6em;
    font-weight: 400;
    padding: 0.8rem 1.4rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
}
@media (max-width: 1100px) {
  .header__list-items {
    font-size: 1.4em;
    font-weight: 400;
    padding: 0.7rem 1.2rem;
    margin-top: 1.6rem;
    margin-bottom: 2rem;
  }
}
.header__list-items:last-child {
  background-color: #50c7e8;
  margin-left: 1rem;
  margin-right: 2rem;
}
.header__list-items:hover {
  cursor: pointer;
}

#header__list-link-buy {
  color: black;
}
@media (max-width: 850px) {
  #header__list-link-buy {
    color: #4fc7e7;
  }
}

a.anchor {
  display: block;
  position: relative;
  top: -15rem;
  visibility: hidden;
}
@media (max-width: 1500px) {
  a.anchor {
    top: -10rem;
  }
}
@media (max-width: 1100px) {
  a.anchor {
    top: -8rem;
  }
}

.nav__list--collapsed {
  margin-top: 8rem;
}
@media (max-width: 650px) {
  .nav__list--collapsed {
    margin-top: 20rem;
  }
}
.nav__item--collapsed {
  list-style: none;
  margin: 2rem;
}
.nav__link--collapsed:link, .nav__link--collapsed:visited {
  display: inline-block;
  font-size: 4rem;
  padding: 1rem 2rem;
  text-decoration: none;
  color: #ffffff;
  background-image: linear-gradient(120deg, transparent 0%, transparent 50%, white 50%);
  background-size: 240%;
  transition: all 0.4s;
}
@media (max-width: 650px) {
  .nav__link--collapsed:link, .nav__link--collapsed:visited {
    font-size: 2.5rem;
  }
}
.nav__link--collapsed:link:hover, .nav__link--collapsed:visited:hover {
  color: #0a0a0a;
  font-size: 5rem;
}
@media (max-width: 650px) {
  .nav__link--collapsed:link:hover, .nav__link--collapsed:visited:hover {
    font-size: 3rem;
  }
}
.nav__link--collapsed:hover, .nav__link--collapsed:active {
  background-position: 100%;
  cursor: pointer;
  font-size: 6rem;
  transform: translateY(3px);
}
@media (max-width: 650px) {
  .nav__link--collapsed:hover, .nav__link--collapsed:active {
    font-size: 4rem;
  }
}
.nav__collapsed {
  display: none;
  margin-left: auto;
  margin-top: 3rem;
  position: relative;
  text-align: center;
}
@media (max-width: 850px) {
  .nav__collapsed {
    display: inline-block;
  }
}
.nav__nav {
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1500;
  opacity: 0;
  width: 0;
  transition: all 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}
.nav__checkbox {
  display: none;
}
.nav__checkbox:checked ~ .nav__background {
  transform: scale(80);
}
.nav__checkbox:checked ~ .nav__nav {
  width: 100%;
  opacity: 1;
}
.nav__button {
  background-color: #171717;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  position: fixed;
  top: 0.5rem;
  right: 2.75rem;
  z-index: 2000;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
}
.nav__background {
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 50%;
  position: fixed;
  top: 1rem;
  right: 3rem;
  background-image: radial-gradient(#1b1b1b, #0a0a0a);
  z-index: 1000;
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}
.nav__icon {
  position: relative;
  margin-top: 2.4rem;
}
.nav__icon, .nav__icon::before, .nav__icon::after {
  width: 2.5rem;
  height: 2px;
  background-color: #4fc7e7;
  display: inline-block;
}
.nav__icon::before, .nav__icon::after {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.2s;
}
.nav__icon::before {
  top: -0.8rem;
}
.nav__icon::after {
  top: 0.8rem;
}
.nav__button:hover .nav__icon::before {
  top: -1rem;
}
.nav__button:hover .nav__icon::after {
  top: 1rem;
}
.nav__checkbox:checked + .nav__button .nav__icon {
  background-color: transparent;
}
.nav__checkbox:checked + .nav__button .nav__icon::before {
  top: 0;
  transform: rotate(135deg);
}
.nav__checkbox:checked + .nav__button .nav__icon::after {
  top: 0;
  transform: rotate(45deg);
}

