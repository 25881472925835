*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

$primary-color: black;
$secondary-color: #4fc7e7;
$tertiary-color: #333;
$white-color: white;
$chart-grey: #e8f3f7;
$chart-blue: #dcf4fa;
$background-blue: #baeaf7;
$light-grey-color: #aaa;
$off-white: #bfbfbf;

html {
  font-size: 62.5%;
}

body {
  font-family: 'canada-type-gibson', sans-serif;
  color: $tertiary-color;
}
