.header {
  background-color: #000;
  height: 10rem;
  display: flex;
  justify-content: space-between;
  z-index: 1000000 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;

  @media (max-width: 1500px) {
    height: 8rem;
  }
  @media (max-width: 1100px) {
    height: 6rem;
  }

  &__logo {
    height: 7rem;
    margin-left: 4rem;
    margin-top: 2rem;

    @media (max-width: 1500px) {
      height: 5rem;
      margin-left: 3rem;
      margin-top: 1.5rem;
    }
    @media (max-width: 1100px) {
      height: 4rem;
      margin-left: 2rem;
      margin-top: 1rem;
    }

    @media(max-width: 650px) {
      height: 3.5rem;
    }
  }

  &__nav {

    @media(max-width: 850px) {
        display: none;
    }
  }

  &__list {
    display: flex;
    justify-content: end;
    list-style-type: none;
    margin: 0 5rem;

    @media (max-width: 1500px) {
      margin: 0 3rem;
    }

    @media (max-width: 1100px) {
      margin: 0 2rem;
    }

    &-link {
      text-decoration: none;
      color: $light-grey-color;
      transition: all 0.2s ease-in-out;

      &:hover,
      &:active,
      &:focus {
        color: $white-color;
      }
    }
    
    &-items {
      font-size: 1.8em;
      font-weight: 400;
      padding: 0.8rem 1.8rem;
      margin-top: 3.5rem;
      margin-bottom: 3rem;

      @media (max-width: 1500px) {
        font-size: 1.6em;
        font-weight: 400;
        padding: 0.8rem 1.4rem;
        margin-top: 2rem;
        margin-bottom: 3rem;
      }

      @media (max-width: 1100px) {
        font-size: 1.4em;
        font-weight: 400;
        padding: 0.7rem 1.2rem;
        margin-top: 1.6rem;
        margin-bottom: 2rem;
      }

      &:last-child {
        background-color: #50c7e8;
        margin-left: 1rem;
        margin-right: 2rem;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

#header__list-link-buy {
  color: $primary-color;

  @media (max-width: 850px) {
    color: $secondary-color;
  }
}

a.anchor {
  display: block;
  position: relative;
  top: -15rem;
  visibility: hidden;

  @media (max-width: 1500px) {
    top: -10rem;
  }
  @media (max-width: 1100px) {
    top: -8rem;
  }
}

.nav {
  &__list--collapsed {
    margin-top: 8rem;

    @media(max-width: 650px) {
      margin-top: 20rem;
    }
  }
    
  &__item--collapsed {
    list-style: none;
    margin: 2rem;
  }

    
  &__link--collapsed:link,
  &__link--collapsed:visited {
      display: inline-block;
      font-size: 4rem;
      padding: 1rem 2rem;
      text-decoration: none;
      color: #ffffff;
      background-image: linear-gradient(120deg, transparent 0%, transparent 50%, white 50%);
      background-size: 240%;
      transition: all .4s;

      @media(max-width: 650px) {
          font-size: 2.5rem;
      }

      &:hover {
        color: #0a0a0a;
        font-size: 5rem;

        @media(max-width: 650px) {
            font-size: 3rem;
        }
      }
  }
  
  &__link--collapsed:hover,
  &__link--collapsed:active {
      background-position: 100%;
      cursor: pointer;
      font-size: 6rem;
      transform: translateY(3px);

      @media(max-width: 650px) {
          font-size: 4rem;
      }
  }

  &__collapsed {
      display: none;
      margin-left: auto;
      margin-top: 3rem;
      position: relative;
      text-align: center;

      @media(max-width: 850px) {
          display: inline-block;
      }
  }

  &__nav {
      height: 100vh;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 1500;
      opacity: 0;
      width: 0;
      transition: all .8s cubic-bezier(0.86, 0, 0.07, 1);
  }

  &__checkbox {
      display: none;
  }

  &__checkbox:checked ~ &__background {
      transform: scale(80);
  }

  &__checkbox:checked ~ &__nav {
      width: 100%;
      opacity: 1;
  }

  &__button {
      background-color: #171717;
      height: 5rem;
      width: 5rem;
      border-radius: 50%;
      position: fixed;
      top: 0.5rem;
      right: 2.75rem;
      z-index: 2000;
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, .1);
      text-align: center;
      cursor: pointer;
  }

  &__background {
      height: 4.5rem;
      width: 4.5rem;
      border-radius: 50%;
      position: fixed;
      top: 1rem;
      right: 3rem;
      background-image: radial-gradient(#1b1b1b, #0a0a0a);
      z-index: 1000;
      transition: transform .8s cubic-bezier(0.86, 0, 0.07, 1);
  }

  &__icon {

      position: relative;
      margin-top: 2.4rem;

      &,
      &::before,
      &::after {
          width: 2.5rem;
          height: 2px;
          background-color: $secondary-color;
          display: inline-block;
      }

      &::before,
      &::after {
          content: "";
          position: absolute;
          left: 0;
          transition: all .2s;
      }

      &::before {
          top: -0.8rem;
      }

      &::after {
          top: 0.8rem;
      }
  }

  &__button:hover &__icon::before {
      top: -1rem;
  }

  &__button:hover &__icon::after {
      top: 1rem;
  }

  &__checkbox:checked + &__button &__icon {
      background-color: transparent;
  }

  &__checkbox:checked + &__button &__icon::before {
      top: 0;
      transform: rotate(135deg);
  }

  &__checkbox:checked + &__button &__icon::after {
      top: 0;
      transform: rotate(45deg);
  }
}