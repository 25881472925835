.fitment {

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: $background-blue;
    position: relative;

    @media(max-width: 900px) {
      display: flex;
      flex-direction: column;
    }
  }
  
  &__content {
    padding: 8rem;
    padding-bottom: 0rem;
    padding-left: 10rem;
    width: 55vw;
    margin: 0 auto;

    @media(max-width: 900px) {
      padding: 2rem;
      width: 90%;
    }
    
    @media (max-width: 650px) {
      padding-top: 2rem;
    }
  }
  
  &__heading {
    color: $primary-color;
    font-size: 5rem;
    line-height: 5.5rem;
    font-weight: 500;

    @media (max-width: 850px) {
      font-size: 4rem;
      line-height: 4rem;
    }

    &--secondary {
      font-size: 3rem;
      font-weight: 500;
      color: $primary-color;
      margin: 20rem 5rem 3rem 0rem;

      @media(max-width: 900px) {
        margin: 2rem;
        text-align: center;
      }
    
      @media (max-width: 650px) {
        font-size: 2rem;
      }

      &-boxes {
    
        @media (max-width: 900px) {
          text-align: center;
          padding-right: 0rem;
        }

        & > p {
          font-size: 1.2rem;
          margin-top: 0.5rem;
          width: 90%;
    
          @media (max-width: 900px) {
            padding-left: 3rem;
            font-size: 1rem;
          }
        }
      }
    }
    
    &--sub {
      font-size: 2rem;
      font-weight: 400;
      padding: 2rem 1rem;
      line-height: 2.8rem;
      padding-left: 0rem;

      @media(max-width: 1200px) {
        font-size: 1.6rem;
      }

      @media(max-width: 850px) {
        line-height: 2.4rem;
      }
    
      @media (max-width: 650px) {
        font-size: 1.6rem;
      }

      &-cols {
        display: flex;

        @media(max-width: 900px) {
          display: block;
        }
      }
    }
  }

  &__dropdowns > select {
    height: 4rem;
    width: 30%;
    margin-right: 1rem;
    font-family: 'canada-type-gibson', sans-serif;
    font-size: 2rem;

    @media(max-width: 1200px) {
      height: 3rem;
      font-size: 1.6rem;
    }

    &:hover {
      cursor: pointer;
    }
  }
  
  &__img {
    max-width: 100%;
    grid-column: 1 / -1;
    margin: 0 auto;
  }

  &__chart {
    position: absolute;
    top: 55rem;
    z-index: 1000;
    width: 100%;
    margin: 0 auto;

    @media(max-width: 950px) {
      top: 60rem;
    }

    @media(max-width: 650px) {
      top: 50rem;
    }

    & > div > table {

      @media(max-width: 650px) {
        width: 100%;
      }
    }

    &--title {
      color: $white-color;
      background-color: $primary-color;
      font-weight: 400;
      font-size: 3rem;
      width: 30rem;
      padding: 1rem;
      border: 1px solid #333;
      text-align: center;

      @media(max-width: 1200px) {
        font-size: 2rem;
      }

      @media(max-width: 650px) {
        font-size: 1.4rem;
      }
    }

    &--data {
      background-color: $chart-grey;
      font-size: 2.5rem;
      padding: 1rem;
      border: 1px solid #333;
      text-align: center;

      @media(max-width: 1200px) {
        font-size: 2rem;
      }

      @media(max-width: 650px) {
        font-size: 1.2rem;
      }
    }
  }

  &__button {
    margin-top: 3rem;
    padding: 1rem 3rem;
    font-family: 'canada-type-gibson', sans-serif;
    font-size: 2em;
    color: $secondary-color;
    background-color: $white-color;
    border: 2px solid $secondary-color;

    &:hover {
      cursor: pointer;
    }
  }
}