.specs {

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow-x: hidden;

    @media (max-width: 1100px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__content {
    padding: 6rem;
    width: 45vw;
    margin: 0 auto;
    padding-bottom: 0rem;

    @media (max-width: 1100px) {
      width: 95%;
      padding: 3rem 1rem;
    }

    @media (max-width: 650px) { 
      padding-bottom: 0rem;
    }
  }

  &__heading {
    color: $primary-color;
    font-size: 5rem;
    font-weight: 500;
    line-height: 5.5rem;

    @media (max-width: 850px) {
      font-size: 4rem;
      line-height: 4rem;
    }

    &--box {
      width: 60%;

      @media (max-width: 850px) {
        width: 95%;
      }
    }

    &--sub {
      font-size: 1.8rem;
      font-weight: 400;
      padding: 0.5rem 0;
      line-height: 2.8rem;

      @media (max-width: 1700px) {
        font-size: 1.6rem;
      }

      @media (max-width: 1250px) {
        font-size: 1.4rem;
      }

      @media (max-width: 850px) {
        line-height: 2.4rem;
        padding-left: 1rem;
      }

      @media (max-width: 400px) {
        margin-bottom: 4rem;
      }
    }
  }

  &__img {
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    justify-self: center;
    align-self: center;
    height: 80%;

    @media (max-width: 1700px) {
      height: 70%;
    }

    @media (max-width: 1250px) {
      height: 60%;
    }

    @media (max-width: 1100px) {
      height: 100%;
    }

    @media (max-width: 650px) { 
      height: 60%;
    }

    &--box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 17rem 50rem 17rem;
      width: 90%;

      @media (max-width: 1700px) {
        grid-template-rows: 30% 40% 30%;
      }

      @media (max-width: 1100px) {
        margin: 0 auto;
        height: 50rem;
        width: 70%;
        margin-top: -2rem;
        margin-bottom: 5rem;
      }

      @media (max-width: 850px) { 
        width: 90%;
      }

      @media (max-width: 650px) { 
        grid-template-rows: 40% 20% 40%;
      }

      @media (max-width: 450px) {
        grid-template-rows: 30% 40% 30%;
        margin-top: 0.5rem;
      }
    }

    &--rule {
      grid-row: 2 / 3;
      grid-column: 1 / -1;
      border-top: 2px solid $secondary-color;
      border-bottom: 2px solid $secondary-color;
    }

  }
  &__text {
    &--1 {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      align-self: end;
      width: 48%;
      margin-bottom: 5rem;

      @media (max-width: 1700px) {
        font-size: 1.8rem;
        margin-bottom: 3rem;
      }

      @media (max-width: 1250px) {
        font-size: 1.6rem;
        margin-bottom: 2.8rem;
      }

      @media (max-width: 450px) {
        align-self: start;
        min-width: 9rem;
      }
    }
    &--2 {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
      align-self: end;
      justify-self: end;
      text-align: end;
      width: 48%;
      margin-bottom: 2.5rem;

      @media (max-width: 1700px) {
        font-size: 1.8rem;
        margin-bottom: 3rem;
      }

      @media (max-width: 1250px) {
        font-size: 1.6rem;
        margin-bottom: 1rem;
      }

      @media (max-width: 450px) {
        align-self: start;
        min-width: 11rem;
      }
    }
    &--3 {
      grid-row: 3 / 4;
      grid-column: 1 / 2;
      align-self: start;
      width: 50%;
      margin-top: 2rem;

      @media (max-width: 1700px) {
        font-size: 1.8rem;
      }

      @media (max-width: 1250px) {
        font-size: 1.6rem;
        margin-top: 1rem;
      }

      @media (max-width: 450px) {
        align-self: end;
        min-width: 11rem;
      }
    }
    &--4 {
      grid-row: 3 / 4;
      grid-column: 2 / 3;
      align-self: start;
      justify-self: end;
      text-align: end;
      width: 48%;
      margin-top: 2rem;

      @media (max-width: 1700px) {
        font-size: 1.8rem;
      }

      @media (max-width: 1250px) {
        font-size: 1.6rem;
        width: 42%;
        margin-top: 1rem;
        min-width: 11rem;
      }

      @media (max-width: 450px) {
        align-self: end;
      }
    }
  }

  &__chart {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    &--title {
      color: $white-color;
      background-color: $primary-color;
      font-weight: 500;
      padding: 1rem;
      font-size: 1.8rem;
      margin: 0;

      @media (max-width: 1250px) {
        font-size: 1.6rem;
      }

      @media (max-width: 450px) {
        font-size: 1.2rem;
      }

      @media (max-width: 350px) {
        font-size: 1rem;
      }
    }

    &--data {
      display: flex;
      height: 26.8rem;

      @media (max-width: 1250px) {
        height: 25rem;
      }
      &-odd {
        background-color: $chart-grey;
        width: 33.33%;        
      }
      &-even {
        background-color: $chart-blue;
        width: 33.33%;
      }
    }

    &--spec {
      font-size: 1.6em;
      font-weight: 500;
      padding: 0.5rem;
      color: $primary-color;

      @media (max-width: 1250px) {
        font-size: 1.4rem;
      }

      @media (max-width: 450px) {
        font-size: 1rem;
      }

      @media (max-width: 350px) {
        font-size: 0.8rem;
      }

      &:nth-child(even) {
        background-color: $off-white;
      }
    }
  }

  &__button {
    margin-top: 2rem;
    padding: 1rem 3rem;
    font-family: 'canada-type-gibson', sans-serif;
    font-size: 2em;
    color: $secondary-color;
    background-color: $white-color;
    border: 2px solid $secondary-color;

    &:hover {
      cursor: pointer;
    }
  }
}

#first__box {
  height: 3rem;

  @media (max-width: 450px) {
    height: 2.6rem;
  }
}