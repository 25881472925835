.landing {
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  &__image {
    margin: 0 auto;
    width: 100%;
  }

  &__carousel {
    max-width: 100vw;
    
    &--item {
      width: 100vw;
    }
    
    &--img {
      margin: 0 auto;
      width: 100vw;
    }
  }
}