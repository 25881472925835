.buy {
  &__section {
    background-color: #e6e7e8;

    @media (max-width: 750px) {
      min-height: 60rem;
    }
    @media (max-width: 600px) {
      min-height: 70rem;
    }
    @media (max-width: 450px) {
      min-height: 90rem;
    }
    @media (max-width: 400px) {
      min-height: 100rem;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: 60% 40%;
    position: relative;

    @media (max-width: 450px) {
      display: flex;
      flex-direction: column;
    }

    // @media (max-width: 1100px) {
    //   display: flex;
    //   flex-direction: column;
    // }
  }

  &__content {
    padding-top: 4rem;
    padding-right: 0;
    width: 80%;
    margin: 0 auto;
    z-index: 1000;

    @media (max-width: 850px) {
      padding-top: 3rem;
    }
  }

  &__heading {
    color: $primary-color;
    font-size: 5rem;
    font-weight: 500;

    @media (max-width: 850px) {
      font-size: 4rem;
    }

    &--sub {
      font-size: 2rem;
      font-weight: 400;
      padding: 1rem 0;
      line-height: 2.8rem;
      color: $primary-color;

      @media (max-width: 850px) {
        line-height: 2.4rem;
        font-size: 1.6rem;
      }
    }
  }

  &__banner {
    max-width: 100%;
    margin-top: 2rem;

    @media (max-width: 750px) {
      display: none;
    }
  }

  &__locations {
    display: flex;
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    // margin-right: 5rem;
    margin: 0 auto;

    // @media (max-width: 850px) {
    //   display: flex;
    //   flex-direction: column;
    //   margin-right: 0rem;
    //   margin-bottom: 3rem;
    // }
  }

  &__store {
    margin-top: 4rem;
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 0.5rem;

    // @media (max-width: 850px) {
    //   margin-top: 3rem;
    //   height: 22rem;
    // }
  }

  &__stores {
    position: absolute;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8rem;
    left: 0;
    right: 0;
    text-align: center;
    background-color: #ffffff;
    border: 2px solid black;

    @media (max-width: 1250px) {
      font-size: 1rem;
    }

    @media (max-width: 1000px) {
      margin-top: 2rem;
    }

    @media (max-width: 750px) {
      grid-template-columns: repeat(4, 1fr);
    }

    &--title-domestic {
      grid-column: 1 / -1;
      padding: 2rem;
      margin-bottom: 0;
      border: 1px solid black;

      @media (max-width: 1250px) {
        font-size: 1.6rem;
        padding: 1rem;
      }

      @media (max-width: 1100px) {
        padding: 0.5rem;
      }
    }

    &--links {
      text-decoration: none;
      color: #333;
      cursor: pointer;

      @media (max-width: 1250px) {
        font-size: 1.2rem;
      }
    }

    &--location {
      border: 1px solid black;
      padding-top: 2rem;
      padding-bottom: 1rem;
      height: 100%;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: white;
        background-color: black;
      }

      @media (max-width: 1100px) {
        padding: 0.5rem;
      }

    }
  }

  &__img {
    height: 12rem;

    // @media (max-width: 850px) {
    //   height: 15rem;
    // }
  }

  &__text {
    position: absolute;
    width: 50%;
    top: 15rem;
    right: 10rem;
    &--heading {
      font-size: 5.5rem;
      font-weight: 500;
      font-style: italic;
      color: $primary-color;
    }
    &--sub-heading {
      font-size: 3rem;
      font-weight: 500;
      color: $primary-color;
    }
    &--sub-text {
      font-size: 2rem;
      font-weight: 400;
      padding: 1rem 0;
      line-height: 2.8rem;
    }
    &--job-title {
      font-size: 2rem;
      font-weight: 200;
      font-style: italic;
      color: $primary-color;
    }
  }

  &__button {
    padding: 1rem 3rem;
    font-family: 'canada-type-gibson', sans-serif;
    font-size: 2em;
    color: $primary-color;
    background-color: $white-color;
    border: 2px solid $primary-color;
    z-index: 100000;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    text-align: center;
    // display: none;

    // @media (max-width: 850px) {
    //   width: 35rem;
    // }

    @media (max-width: 400px) {
      width: 80%;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }
}

#buy {
  margin-top: 1rem;
}

#summit__image {
  margin-top: 5px;
  height: 11rem;
}