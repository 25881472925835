.promise {

  &__container {
    display: grid;
    grid-template-columns: 30% 70%;
    overflow: hidden;
    margin-top: 2rem;

    @media (max-width: 1150px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__content {
    padding: 8rem;
    padding-right: 0;
    padding-left: 10rem;
    width: 120%;
    margin: 0 auto;
    z-index: 1000;
    margin-top: 8rem;

    @media (max-width: 1450px) {
      width: 100%;
      font-size: 1rem;
    }

    @media (max-width: 1150px) {
      padding-bottom: 3rem;
      padding-top: 5rem;
      padding-right: 4rem;
      margin-top: 8rem;
    }

    @media (max-width: 850px) {
      padding: 4rem;
      padding-top: 6rem;
      margin-bottom: 2rem;
    }

    @media (max-width: 600px) {
      margin-top: 5rem;
      margin-bottom: 8rem;
    }
  }

  &__heading {
    color: $primary-color;
    font-size: 5rem;
    font-weight: 500;

    @media (max-width: 850px) {
      font-size: 4rem;
    }

    &--sub {
      font-size: 2rem;
      font-weight: 400;
      padding: 1rem 0;
      line-height: 2.8rem;
      margin-bottom: 5rem;

      @media (max-width: 1450px) {
        font-size: 1.6rem;
      }

      @media (max-width: 850px) {
        padding-bottom: 0rem;
        line-height: 2.4rem;
      }
    }
  }

  &__img {
    margin-top: 4rem;
    max-width: 100%;

    @media (max-width: 600px) {
      margin-top: -1rem;
    }

    @media (max-width: 600px) {
      margin-top: 15rem;
    }
  }

  &__phrase {
    position: relative;
    margin-bottom: -0.3rem;

    @media (max-width: 1150px) {
      margin-top: -5rem;
    }
  }

  &__text {
    position: absolute;
    width: 50%;
    top: 8rem;
    right: 10rem;

    @media (max-width: 1650px) {
      top: 8rem;
      right: 8rem;
    }

    @media (max-width: 1300px) {
      top: 6rem;
      right: 6rem;
    }

    @media (max-width: 750px) {
      top: 4rem;
      right: 4rem;
    }

    @media (max-width: 700px) {
      font-size: 1.6rem;
    }

    @media (max-width: 600px) {
      margin-top: -5rem;
      width: 80%;
    }

    &--box {
      margin-left: 30rem;
      margin-top: 2rem;

      @media (max-width: 1250px) {
        margin-left: 10rem;
        margin-top: -2rem;
      }

      @media (max-width: 750px) {
        margin-top: 0.5rem;
        margin-left: 5rem;
      }

      @media (max-width: 700px) {
        margin-top: 0rem;
        margin-left: 5rem;
      }
    } 

    &--heading {
      font-size: 5rem;
      font-weight: 500;
      font-style: italic;
      color: $primary-color;

      @media (max-width: 1650px) {
        font-size: 4rem;
      }

      @media (max-width: 1350px) {
        font-size: 3rem;
      }

      @media (max-width: 700px) {
        font-size: 2rem;
      }
    }
    &--sub-heading {
      font-size: 3rem;
      font-weight: 500;
      color: $primary-color;

      @media (max-width: 1350px) {
        font-size: 2rem;
      }

      @media (max-width: 700px) {
        margin-top: -1rem;
        font-size: 1.6rem;
      }
    }
    &--sub-text {
      font-size: 2rem;
      font-weight: 400;
      padding: 1rem 0;
      line-height: 2.8rem;

      @media (max-width: 1350px) {
        font-size: 1.6rem;
      }

      @media (max-width: 750px) {
        font-size: 1.4rem;
        line-height: 2rem;
      }

      @media (max-width: 700px) {
        font-size: 1.3rem;
        line-height: 1.6rem;
      }
    }
    &--job-title {
      font-size: 2rem;
      font-weight: 200;
      font-style: italic;
      color: $primary-color;

      @media (max-width: 1350px) {
        font-size: 1.6rem;
      }

      @media (max-width: 700px) {
        margin-top: -1rem;
        font-size: 1.3rem;
        font-weight: 500;
      }
    }
  }

  &__button {
    padding: 1rem 3rem;
    font-family: 'canada-type-gibson', sans-serif;
    font-size: 2em;
    color: $primary-color;
    background-color: $white-color;
    border: 2px solid $primary-color;
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      font-size: 2.3em;
    }

    @media (max-width: 1150px) {
      margin-left: 60%;
    }

    @media (max-width: 600px) {
      margin: 0 auto;
    }
  }
}

#our-promise {
  margin-top: -10rem;
}