.footer {
  background-color: $primary-color;
  height: 10rem;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;

  @media(max-width: 550px) {
      height: 8rem;
  }
  
  &__logo {
    margin: 0 auto;
    margin-top: 3rem;

    @media(max-width: 850px) {
        width: 30rem;
    }

    @media(max-width: 550px) {
      margin-top: 2rem;
      width: 20rem;
    }
  }

  &__social {
    margin: 0 auto;
    width: 20rem;
    display: flex;
    justify-content: space-between;

    @media(max-width: 550px) {
      width: 10rem;
    }

    &--icon {
      height: 3rem;
      transition: all 0.2s ease-in-out;

      @media(max-width: 550px) {
        height: 2rem;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &__text {
    font-size: 1.1rem;
    margin: 0 auto;
    padding-top: 1rem;
    color: #e0e0e0;

    @media(max-width: 550px) {
      font-size: 1rem;
    }
  }
}